import Vue from "vue";
import Component from "vue-class-component";
import * as VgeEnums from "@/enums/vge";
import { Mixins } from "vue-property-decorator";
import { HasEnumMethods } from "../has-enum-methods";

@Component
export class HasVgeOptions extends Mixins(HasEnumMethods) {
  VgeImmoSignSetupOptions = this.optionsFromEnum(VgeEnums.ImmoSignSetup, "vge.enums.sign_setup", true);
  VgeImmoSignPlacementDetailOptions = this.optionsFromEnum(VgeEnums.ImmoSignPlacementDetail, "vge.enums.sign_placement_detail", true);
  VgeImmoSignColorOptions = this.optionsFromEnum(VgeEnums.ImmoSignColor, "vge.enums.sign_color", true);
  VgeImmoSignDesignOptions = this.optionsFromEnum(VgeEnums.ImmoSignDesign, "vge.enums.design", true);
}
