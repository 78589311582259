
  import { Action } from "vuex-class";
  import { Component, Mixins } from "vue-property-decorator";
  import { FormBase, FormError, FormInput, FormSelect, FormDate, FormSubmit, FormTextarea, FormCheckbox, FormYesNo } from "@/components/forms";
  import { formatErrors } from "@/utils/formatters";
  import { HasVgeModal } from "@/mixins/vge/has-vge-modal";
  import { ImmoSignType } from "@/store/modules/partner.store";
  import { ImmoSignLayout } from "@/store/modules/vastgoedexperts.store";
  import { HasVgeOptions } from "@/mixins/vge/has-options";
  import { DocumentType } from "@/store/modules/document.store";

  @Component({
    components: {
      LayoutModal: () => import("@/layouts/components/modal.vue"),
      FormBase,
      FormInput,
      FormDate,
      FormTextarea,
      FormSelect,
      FormError,
      FormCheckbox,
      FormYesNo,
      FormSubmit,
    },
  })
  export default class AddVgeSignsModal extends Mixins(HasVgeModal, HasVgeOptions) {
    @Action("vastgoedexperts/createImmoSigns") createImmoSigns!: (payload: any) => Promise<void>;
    @Action("vastgoedexperts/readImmoSignLayouts") readImmoSignLayouts!: (activity_id: number) => Promise<ImmoSignLayout[]>;

    ImmoSignType = ImmoSignType;

    modalType = "show-vge-add-signs";

    layoutOptions: any = [];

    documents = [DocumentType.vgeImmoSignPhotos, DocumentType.vgeImmoSignDocumentationExtra, DocumentType.vgeImmoSignDesignFiles];

    get computedDocuments() {
      if (this.payload.layout_id && this.payload.layout_id == 0) {
        return [DocumentType.vgeImmoSignPhotos, DocumentType.vgeImmoSignDocumentationExtra, DocumentType.vgeImmoSignDesignFiles];
      }

      return [DocumentType.vgeImmoSignPhotos, DocumentType.vgeImmoSignDocumentationExtra];
    }

    handleAfterOpen() {
      this.readImmoSignLayouts(this.activity.id).then((layouts: ImmoSignLayout[]) => {
        this.layoutOptions = layouts.map((layout: ImmoSignLayout) => {
          const nameSuffix = layout.opdruk && layout.formaat ? `- ${layout.opdruk} - ${layout.formaat}` : "";
          const stock = layout.stock ? ` - (${layout.stock} stuks)` : "";
          const type = layout.type ? layout.type : "";

          return {
            ...layout,
            value: layout.layout_id,
            label: layout.name ? layout.name : `${type} ${nameSuffix} ${stock}`,
          };
        });
      });
    }

    async submit(form: FormClass) {
      try {
        this.sanitizePayload();

        this.payload.subtype = ImmoSignType.ImmoSignDefault;

        await this.createImmoSigns(this.payload);

        this.handleClose();

        if (this.callback && this.partner) {
          this.callback(this.partner);
        }

        this.$toast.open({ message: "Immo borden aangevraagd", type: "success" });
      } catch (e) {
        this.errorResponse = formatErrors(e);
      } finally {
        form.reset();
      }
    }
  }
