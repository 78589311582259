export enum ImmoSignSetup {
  SingleSidedRack = "0",
  DoubleSidedRack = "1",
  VRack = "2",
  GallowsSetup = "3",
}

export enum ImmoSignPlacementDetail {
  InTheGround = "0",
  AgainstTheFacade = "1",
  WithBallast = "2",
  OnTheBalustrade = "3",
  StretchedBetweenWindowPlacement = "4",
}

export enum ImmoSignColor {
  WhitePainted = "0",
  BrownGardenWood = "1",
  BlackImpregnated = "2",
}

export enum ImmoSignDesign {
  DesignByVgE = "0",
  DesignByCustomer = "1",
}
